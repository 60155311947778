// ErrorPage.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PAGE_TYPES } from '@fronend/common/constants';
import { getHostName, getPathName, getURLHref, getPageData, getRenderOut } from '@fronend/common/utils';
import { fetchHomeDataIfNeeded } from '@components/Home/HomeActions';
import { connect } from 'react-redux';
import './../../../reducers/errorpage/errorpage';
import { json } from 'stream/consumers';
import logger from '../../../logging/log';
const cookie = require('cookie'); // Add this import


ErrorPage.events = {
	SUCCESS: 'STATIC_PAGE_SUCCESS',
	FAILURE: 'STATIC_PAGE_FAILURE'
};

ErrorPage.fetchData = async function ({ dispatch, query, params, cookies, headers, hostname, req, res, loading }) {
	return dispatch(fetchHomeDataIfNeeded(params, query, cookies, headers, hostname, req, PAGE_TYPES.ErrorPage, ErrorPage.events, res, loading));
};

// Function to get a specific cookie value by name
const getCookieValue = async (sourcePath) => {
	return new Promise((resolve) => {
		try {
			// Access cookies here
			const cookies = document.cookie || ''; // Avoid null/undefined issues

			// Validate and sanitize cookies before splitting
            if (typeof cookies !== 'string' || !cookies.trim()) {
                resolve([null, null]);
                return;
            }

            const cookieArray = cookies.split('; ').filter((cookie) => Boolean(cookie) && cookie.trim() !== '');

			let softRedirectUrl: any = null;
			let pageType: any = null;

			for (let i = cookieArray.length - 1; i >= 0; i--) {

				if (!cookieArray[i]) {
					continue; // Skip to next iteration if cookieArray[i] is falsy
				}

				// Attempt to parse the cookie as JSON
				let cookies_json;
				try {
					cookies_json = JSON.parse(cookieArray[i]);
				} catch (error) {
					continue; // Skip to next iteration if JSON parsing fails
				}

				if (cookies_json) {
					const redirectionPathCookie = cookies_json.redirectionPathCookie;
					const pageTypeCookie = cookies_json.pageTypeCookie;

					if (redirectionPathCookie) {
						const redirectionPath_Cookie = redirectionPathCookie.split('=');
						if (redirectionPath_Cookie[0] === sourcePath) {
							const redirectionPath = decodeURIComponent(redirectionPath_Cookie[1]);
							softRedirectUrl = redirectionPath;

							if (pageTypeCookie) {
								const pageType_Cookie = pageTypeCookie.split('=');
								pageType = pageType_Cookie[1];

								if (pageType && pageType === 'Home') {
									pageType = PAGE_TYPES.HomePage;
									break;
								}
								break;
							}
						}
					}
				}
			}
			resolve([softRedirectUrl, pageType]);

		} catch (error) {
			logger.error(error, "error: ");
		}
	});
}


function ErrorPage(props: { data: any, iscsr: any; }) {
	const [pageJson, setpageJson] = useState<any>(null);
	// const [softRedirectUrl, setSoftRedirectUrl] = useState<string | null>(null);
	// const [pageType, setPageType] = useState<string | null>(null);
	let _softRedirectUrl = null;
	let _pageType = null;

	useEffect(() => {

		if (!props.data && !pageJson) {
			const hostname = getHostName();
			const browserUrl = getURLHref();
			let _query = "";
			if (browserUrl && browserUrl.includes('?')) {
				_query = browserUrl.substring(browserUrl.lastIndexOf('?'));
			}
			let sourcePath = getPathName();
			if (sourcePath && sourcePath.endsWith('/')) {
				sourcePath = sourcePath.replace(/\/+$/, '');
			}
			// Use a promise to wait for the completion of getCookieValue
			const Promise2 = getCookieValue(sourcePath);

			Promise2.then(res => {

				// Extract values from cookieValues
				if (res && Object.keys(res).length == 2) {
					res[0] = res[0] + _query;
					_softRedirectUrl = (res[0]);
					_pageType = (res[1]);
					const Promise1 = getPageData(hostname, res[1]);
					Promise1.then(res2 => { setpageJson(res2); });
					// return (<div> {getRenderOut(props, pageJson, res[0])} </div>);
				} else if (_softRedirectUrl && _pageType) {
					const Promise1 = getPageData(hostname, _pageType);
					Promise1.then(res2 => { setpageJson(res2); });
				} else {
					const Promise1 = getPageData(hostname, PAGE_TYPES.ErrorPage);
					Promise1.then(res2 => { setpageJson(res2); });
				}

			});
		}

	}, [pageJson]);
	return (
		<div>
			{getRenderOut(props, pageJson, _softRedirectUrl)}
		</div>
	);

}


const mapStateToProps = (state) => {
	// Define the possible page data keys
	const pageDataKeys = [
		'home',
		'articlelist',
		'articleshow',
		'amparticleshow',
		'authordetail',
		'photolist',
		'photoshow',
		'podcast',
		'tagpage',
		'topicpage',
		'staticpage',
		'videolist',
		'podcastlist',
		'videoshow',
		'webstorylist',
		'ampphotoshow',
		'podcastlist',
		'ampvideoshow',
		'faqlist',
		'quizshow',
		'quizlist',
		'surveyshow',
		'surveylist'
	];

	// Iterate over the keys to find the first page with data
	let data = null;
	for (const key of pageDataKeys) {
		if (state[key] && state[key].component_data) {
			data = state[key].component_data;
			break;
		}
	}

	// If no page data found, use default from state.staticpage.component_data
	if (!data) {
		data = state.errorpage.component_data;
	}

	return {
		data: data,
		iscsr: state.iscsr
	};
};

export default connect(mapStateToProps)(ErrorPage);

