import React, { Component, ReactElement } from 'react';
import ReactDOMServer from 'react-dom/server';
import { apiUrl_getClientWithDomain, apiUrl_getThemeByClientAndWebsite, apiUrl_getDestinationPath, UrlRedirectionData, PRIMARY_COMPONENTS, SECONDARY_COMPONENTS, SERVER_ENVINORMENTS, gaTagScript, gaMustache, IBEAT_SCRIPT, COMMON_CONST_PARAMS } from './constants';
import { getComponent, getUrl } from 'm360-react-components';
import { Helmet } from "react-helmet";
import { hashSync, setRandomFallback } from 'm360-react-components';
import { getSeoData } from '../components/SeoMetaData/SeoMetaData';
//const fetch = require('node-fetch'); //for windows local running by GS
import logger from '../../logging/log';
import { rejects } from 'assert';

const cookie = require('cookie'); // Add this import


export const getPageData = async (hostname: string, pageType: string, pathname: any = getPathName()) => {

	return new Promise((resolve, reject) => {
		let data: any = {};

		try {
			getClientWithDomain(hostname)
				.then((websiteData: any | null) => {
					if (websiteData) {
						//logger.info("websiteDatawebsiteData", JSON.stringify(websiteData));
						data = websiteData[0];
						data.website = data.websitesList[0];
						data.websitesList[0] = null;
						data.website.hostname = hostname;
						data.website.pathname = pathname;

						const clientId = data.clientId;
						const websiteId = data.website.websiteId;

						let env = SERVER_ENVINORMENTS.PRODUCTION;
						if (data.website.stagingDomain === hostname) {
							env = SERVER_ENVINORMENTS.STAGING;
						}

						if (clientId && websiteId && env && pageType) {
							getTheme(clientId, websiteId, env, pageType)
								.then((themeData: any | null) => {
									if (themeData) {
										data.website.theme = themeData;
										const selectedPages = data.website.theme.renderingPageTemplateList[0];
										if (selectedPages) {
											data.website.theme.page = selectedPages;
											resolve(data);
										}
									} else reject("failed");
								});
						}
					}
				});
		} catch (error) {
			logger.info(error, "error: ");
		}

	});
};

export const getHostName = () => {
	if (window) { return encodeURI(window.location.hostname); }
	return "";
};
export const getPathName = () => {
	if (window) { return encodeURI(window.location.pathname); }
	return "";
};

export const getURLHref = () => {
	if (window) { return encodeURI(window.location.href); }
	return "";
};

export const getMSID = (url: any = null) => {
	if (!url && typeof window !== 'undefined' && undefined !== window && window) {
		url = getURLHref();
	}

	let msid = url;
	if (msid && msid.endsWith('/')) {
		msid = msid.replace(/\/+$/, '');
	}

	//for topics from URL
	if (msid && (msid.includes('/topics/') || msid.includes('/tags/'))) {
		msid = msid.substring(msid.lastIndexOf('/') + 1);
		if (msid && msid.includes('?')) {
			return msid.substring(0, msid.lastIndexOf('?'));
		}
		return msid;
	}
	else if (msid && msid.includes('/') && msid.includes('.')) {
		msid = msid.substring(msid.lastIndexOf('/') + 1);
		if (msid && msid.includes('.')) {
			return msid.substring(0, msid.lastIndexOf('.'));
		}
	}

	return null;
};


export const getSSRComponentsURLs = (json, loading, query, req, pageType, cId) => {
	const componentUrls: any = [];
	findURLsRecusrively(JSON.parse(json.website.theme.page.json), componentUrls, loading, query, req, pageType, cId);
	return componentUrls;
};

export function getcmstype(PageType) {
	const mapping = {
		'HomePage': 'SECTION',
		'ArticleList': 'SECTION',
		'ArticleShow': 'ARTICLE',
		'ArticleShowPreview': 'ARTICLE',
		'AmpArticleShowPreview': 'ARTICLE',
		'PhotoShowPreview': 'PHOTOGALLERYSLIDESHOWSECTION',
		'AmpPhotoShowPreview': 'PHOTOGALLERYSLIDESHOWSECTION',
		'PhotoList': 'PHOTOGALLERYSECTION',
		'PhotoShow': 'PHOTOGALLERYSLIDESHOWSECTION',
		'Podcast': 'MEDIAAUDIO',
		'StaticPage': 'ARTICLE',
		'VideoList': 'MEDIAVIDEOSECTION',
		'VideoShow': 'MEDIAVIDEO',
		'AmpArticleShow': 'ARTICLE',
		'WebstoryList': 'PHOTOGALLERYSECTION',
		'PodcastList': 'MEDIAAUDIOSECTION',
		'AmpPhotoShow': 'PHOTOGALLERYSLIDESHOWSECTION',
		'AmpVideoShow': 'MEDIAVIDEO',
		'FAQList': 'SECTION',
		'QuizShow': 'QUIZ',
		'SurveyShow': 'SURVEY',
		'QuizList': 'SECTION',
		'SurveyList': 'SECTION'
	};

	return mapping[PageType] || null; // Return null if the value is not found in the mapping
};

function getIbeatContentType(PageType) {
	const mapping = {
		'HomePage': '20',
		'ArticleList': '20',
		'ArticleShow': '1',
		'PhotoList': '20',
		'PhotoShow': '3',
		'Podcast': '15',
		'StaticPage': '1',
		'TopicPage': '200',
		'TagPage': '200',
		'VideoList': '20',
		'VideoShow': '2',
		// 'ErrorPage': '',
		'AuthorDetail': '20',
		'AmpArticleShow': '1',
		'WebstoryList': '20',
		'PodcastList': '20',
		'AmpPhotoShow': '3',
		'AmpVideoShow': '2',
		'FAQList': '20',
		'QuizShow': '1',
		'SurveyShow': '1',
		'QuizList': '20',
		'SurveyList': '20'
	};

	return mapping[PageType] || null; // Return null if the value is not found in the mapping
};

const findURLsRecusrively = (json: any[], componentUrls: any[], loading: any, query: any, req, pageType, cId) => {
	const pageId = 1;
	const isLoadMore = false;
	json.map((component: { attributes: { ENDPOINTS: any[] | undefined; }; components: undefined; }) => {
		if (component.attributes && component.attributes.ENDPOINTS !== undefined && component.attributes.ENDPOINTS) {

			for ( let const_key of Object.keys(COMMON_CONST_PARAMS) ) {
				component.attributes[const_key] = eval(COMMON_CONST_PARAMS[const_key]);
			}

			component.attributes.ENDPOINTS.forEach((endpoint: { isSSR: any; name: any; isNotListing: any; loading: any; isListView: any, queryParams: any, preview: any }) => {
				if (endpoint.isSSR) {
					let websiteId = "";
					let clientId = "";
					let preview = false;
					if( Object.keys(endpoint).indexOf("preview") > -1 && endpoint.preview ) {
						preview = endpoint.preview;
					}
					if (endpoint.queryParams !== undefined && endpoint.queryParams) {
						if (endpoint.queryParams.websiteId !== undefined && endpoint.queryParams.websiteId) {
							websiteId = endpoint.queryParams.websiteId;
						}
						if (endpoint.queryParams.clientId !== undefined && endpoint.queryParams.clientId) {
							clientId = endpoint.queryParams.clientId;
						}
					}

					if (typeof endpoint.loading !== "undefined" && endpoint.loading && endpoint.loading == "LAZY") {
						if (loading && loading == "EAGER") { // check for loading = EAGER => listing api called.
							componentUrls.push(getUrl(component.attributes, pageId, isLoadMore, endpoint.name, endpoint.isNotListing, websiteId, clientId, preview));
						}
					} else {
						if (endpoint.isListView !== undefined && endpoint.isListView &&
							endpoint.name !== undefined && endpoint.name && endpoint.name == "MAIN" &&
							endpoint.queryParams !== undefined && endpoint.queryParams && Object.keys(endpoint.queryParams).length > 0 && endpoint.queryParams.cp &&
							query !== undefined && query && Object.keys(query).length > 0 && query.lp) {
							endpoint.queryParams.cp = query.lp;
							componentUrls.push(getUrl(component.attributes, query.lp, isLoadMore, endpoint.name, endpoint.isNotListing, websiteId, clientId, preview));
						} else {
							componentUrls.push(getUrl(component.attributes, pageId, isLoadMore, endpoint.name, endpoint.isNotListing, websiteId, clientId, preview));
						}
					}
				}
			});
		}

		if (component.components !== undefined && component.components) {
			findURLsRecusrively(component.components, componentUrls, loading, query, req, pageType, cId);
		}
	});

	return componentUrls;
};

function isValidDomain(domain: string): boolean {
	// Regex for validating domain names
	const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}$/;
	return domainRegex.test(domain.trim());
}

export async function getClientWithDomain(domain: string) {
	setRandomFallback((len: any) => {
		const arr = new Array(len);
		for (let i = 0; i < len; i++) {
			arr[i] = Math.floor(256);
		}
		return arr;
	});
	try {
		// Validate the domain
		if (!isValidDomain(domain)) {
			logger.info("Invalid domain provided:", domain);
			throw new Error("Invalid domain");
		}

		// Safely construct the API URL using a template
		const safeDomain = encodeURIComponent(domain.trim());

		const apiUrl = apiUrl_getClientWithDomain.replace('{DomainName}', safeDomain);
		let headerString = '?' + apiUrl.split("?")[1];
		headerString = hashSync(headerString, 6);
		const options = {
			headers: {
				'Content-Type': 'application/json',
				'p-nonce': headerString
			},
		};
		// logger.error( JSON.stringify(options) );
		const response = await fetch(apiUrl, options);
		// logger.info(response, "responseresponse");
		if (!response.ok) {
			logger.info(apiUrl, "API request failed 1");
			return null;
		}

		try {
			const websiteData: JSON | null = await response.json();
			if (!websiteData) {
				return null;
			}
			return websiteData;
		} catch (error) {
			logger.info(error, "Error in json parsing :" + response);
		}

	} catch (error) {
		logger.info(error, "API request failed 2");
	}
}

export async function getTheme(clientId: string, websiteId: string, env, pageType: string) {
	setRandomFallback((len: any) => {
		const arr = new Array(len);
		for (let i = 0; i < len; i++) {
			arr[i] = Math.floor(256);
		}
		return arr;
	});
	try {
		const apiUrl = apiUrl_getThemeByClientAndWebsite
			.replace('{ClientId}', clientId)
			.replace('{WebsiteId}', websiteId)
			.replace('{pageType}', pageType)
			.replace('{Environment}', env);

		let headerString = '?' + apiUrl.split("?")[1];
		headerString = hashSync(encodeURI(headerString), 6);
		const options = {
			headers: {
				'Content-Type': 'application/json',
				'p-nonce': headerString
			},
		};
		const response = await fetch(apiUrl, options);
		if (!response.ok) {
			logger.info("API request failed :" + apiUrl);
			return null;
		}
		const themeData: JSON | null = await response.json();
		if (!themeData) {
			logger.info(apiUrl, "No response");
			return null;
		}
		return themeData;
	} catch (error) {
		logger.info(error, "API request failed");
	}
}

export async function getDestinationPath(clientId: string, websiteId: string, sourcePath: string) {
	setRandomFallback((len: any) => {
		const arr = new Array(len);
		for (let i = 0; i < len; i++) {
			arr[i] = Math.floor(256);
		}
		return arr;
	});
	try {
		const apiUrl = apiUrl_getDestinationPath
		.replace('{ClientId}', encodeURIComponent(clientId))
		.replace('{WebsiteId}', encodeURIComponent(websiteId))
		.replace('{sourcePath}', encodeURIComponent(sourcePath));

		let headerString = '?' + apiUrl.split("?")[1];
		headerString = hashSync(headerString, 6);
		const options = {
			headers: {
				'Content-Type': 'application/json',
				'p-nonce': headerString
			},
		};
		const response = await fetch(apiUrl, options);

		// Check if the response status is OK (200)
		if (response.ok) {

			// Check the content type of the response
			const contentType = response.headers.get('content-type');
			if (contentType && contentType.includes('application/json')) {
				// Response is JSON
				const destinationPath: UrlRedirectionData | null = await response.json();
				if (!destinationPath) {
					logger.info("No response: " + apiUrl);
					return null;
				}
				return destinationPath;
			} else {
				// Response is a string
				const text = await response.text();
				if (!text) {
					logger.info(apiUrl, "No response");
				}
				return null;
			}
		} else {
			// Handle other response status codes here
			logger.error('Request failed with status: ' + response.status);
			return null;
		}
	} catch (error) {
		// Handle network errors or other exceptions here
		logger.error(error, 'An error occurred:');
		return null;
	}
}

function appendScriptToHeadOrBody(className, htmlString) {
	const openingTagRegex = /<\s*([^>\s]+)/;
	try {
		let match = htmlString.match(openingTagRegex);

		if (match) {
			let openingTag = match[0];
			let tagName = match[1];

			let finalClassName = "class=" + '"' + className + '"';
			let modifiedOpeningTag = openingTag.replace(tagName, `${tagName} ${finalClassName}`);

			// Replace the original opening tag with the modified one
			let modifiedHTMLString = htmlString.replace(openingTag, modifiedOpeningTag);

			return modifiedHTMLString;
		}
	} catch (error) {
		logger.info(error, "Error while modifying global header/body script ");
	}
	// Return the original string if no opening tag is found
	return htmlString;
}

let SEO_DATA_GLOBAL = null;

export const getRenderOut =  (props: { data: { value: { website: any; }; }, iscsr: any; }, pageJson: { website: any; }, url: any, isAmp: boolean = false) => {
	SEO_DATA_GLOBAL = null;
	let page: any = null;
	let json: any = {};
	let css = '';
	let out: any = 'Loading......';
	let seoData: any;
	var headerScripts: any = [];
	let headerScriptsStr: any = '';
	let headerScriptsStrDefault = "";

	let globalCss = '';
	let globalJson;
	let globalBodyScript = '';
	let globalHeaderScript = '';
	if (props.data) { //ssr
		page = props.data.value.website;
		globalCss = props.data.value.website.theme.css;
		globalJson = JSON.parse(props.data.value.website.theme.json);
	} else if (pageJson) { //csr
		page = pageJson.website;
		globalCss = pageJson.website.theme.css;
		globalJson = JSON.parse(pageJson.website.theme.json);

	}
	var seoDatas: Array<Object> = [];
	let loginEnabled = null;
	let authProvider:any = null;
	let isLoginEnabled = {};

	if (page) {
		loginEnabled = page.loginEnabled;
		authProvider = page.authProvider;
		if( loginEnabled && authProvider && authProvider.providerType && authProvider.appId && authProvider.clientId && authProvider.clientSecret ) {
			isLoginEnabled = { 'isLoginEnabled': true };
		}

		page = page.theme.page;
		json = JSON.parse(page.json);

		if (typeof globalCss === 'undefined' || undefined === globalCss || !globalCss) {
			globalCss = '{}';
		}

		css = page.css.slice(0, -1) + objectToStylesheet(JSON.parse(globalCss)) + ' "';

		if (typeof window !== 'undefined' && undefined !== window && window) {
			if (url !== undefined && url) {
				let RequestURL = url
				if (!url.startsWith('http') && !url.startsWith(getHostName()) && url.startsWith('/')) { RequestURL = "https://" + getHostName() + url; }
				else if (!url.startsWith('http') && !url.startsWith(getHostName())) { RequestURL = "https://" + getHostName() + '/' + url; }
				else if (!url.startsWith('http') && url.startsWith(getHostName())) { RequestURL = "https://" + url; }
				json.map((component, i) => {
					if (component.attributes && component.attributes.ENDPOINTS) {
						component.attributes["REQUEST_URL"] = RequestURL;
					}
				});
			} else {
				json.map((component, i) => {
					if (component.attributes && component.attributes.ENDPOINTS) {
						component.attributes["REQUEST_URL"] = getURLHref();
					}
				});
			}
		}

		var primaryComponents = [];
		replaceIdsInTheURL(json, primaryComponents, false, props.data, headerScripts, url);
		//removing duplication if exist in header script
		const uniqueSet = new Set(headerScripts);
		headerScripts = Array.from(uniqueSet);
		if (headerScripts) {
			headerScripts.forEach((elem: any) => {
				if (elem) {
					headerScriptsStr = headerScriptsStr + elem + '\n';
				}
			});
			if (headerScriptsStr && headerScriptsStr !== undefined && "undefined" !== headerScriptsStr && headerScriptsStr.replace(/(\r\n|\n|\r)/gm, "").length > 0) {
				headerScriptsStrDefault = getUniqueScriptTags(headerScriptsStr);
			}
		}

		const primartCompiledComponents = getComponent(primaryComponents, isLoginEnabled); //DONT REMOVE FOR ASSOCIATION
		const divElements = convertToDivElements(primartCompiledComponents); //DONT REMOVE FOR ASSOCIATION
		// logger.info(divElements, "DivEl"); //DONT REMOVE FOR ASSOCIATION

		replaceIdsInTheURL(json, primartCompiledComponents, true, props.data, headerScripts, url);

		out = getComponent(json, isLoginEnabled);
		out.forEach((comp: any, i: any) => {
			if (!SEO_DATA_GLOBAL && typeof comp !== 'undefined' && typeof comp.props !== 'undefined' && typeof comp.props.data !== 'undefined' && typeof comp.props.data.components !== 'undefined') {
				let i_comps = comp.props.data.components;
				fetSetDataFromComps(i_comps, comp, i, seoDatas);
			}
		});
	}

	if (globalJson && globalJson.globalHeaderScript) {
		globalHeaderScript = appendScriptToHeadOrBody("header_script", globalJson.globalHeaderScript);
	}
	if (globalJson && globalJson.globalBodyScript) {
		globalBodyScript = appendScriptToHeadOrBody("body_script", globalJson.globalBodyScript);
	}
	let isIbeat = true;
	// if(props.data.value.website != undefined && props.data.value.website.websiteResouces != undefined && 
	// 	props.data.value.website.websiteResouces.ibeat != undefined && props.data.value.website.websiteResouces.ibeat) {isIbeat = true;}

	if (props.data) { //ssr

		const ctssr = getIbeatContentType(props.data.value.website.theme.page.type);
		// let isIbeatscript = (typeof window !== 'undefined' && undefined !== window && window) ? IBEAT_SCRIPT.WINDOW_OBJECT.replace('CONTENT_TYPE', ctssr) : "";
		let isIbeatscript = IBEAT_SCRIPT.WINDOW_OBJECT.replace('CONTENT_TYPE', ctssr);
		// logger.info("isIbeatscript 466 : ", isIbeatscript);
		isIbeatscript = isIbeatscript + IBEAT_SCRIPT.ENV;



		// //topic page changes
		// const cookies = document.cookie;
		// console.log("pageJson seo flag  : " , props.data.value.website.cookiesTopicRobots);

		seoData = getSeoData(props.data.value.website, seoDatas);

		// console.log("\n\n\nseoData utils :" , seoData);

		if ((isIbeat && ctssr && !isAmp)) seoData = (typeof seoData !== "undefined" && seoData && seoData)
			+ (typeof headerScriptsStrDefault !== "undefined" && headerScriptsStrDefault && headerScriptsStrDefault)
			+ ((isIbeat && ctssr && !isAmp) && isIbeatscript) + (!isAmp && typeof globalHeaderScript !== "undefined" && globalHeaderScript && globalHeaderScript);
		else if(!isAmp) seoData = (typeof seoData !== "undefined" && seoData && seoData) 
			+ (typeof headerScriptsStrDefault !== "undefined" && headerScriptsStrDefault && headerScriptsStrDefault) 
			+ (!isAmp && typeof globalHeaderScript !== "undefined" && globalHeaderScript && globalHeaderScript);

		if (props.iscsr && props.iscsr == true) {
			return <>
				<Helmet>{seoData}</Helmet>
				{/* <head dangerouslySetInnerHTML={{ __html: seoData }} /> */}
				<div>
					<style dangerouslySetInnerHTML={{ __html: eval(css) }} />
					<span>{out}</span>
					{!isAmp && <div dangerouslySetInnerHTML={{ __html: globalBodyScript }} />}
				</div>
			</>;
		} else {
			return <>
				<head dangerouslySetInnerHTML={{ __html: seoData }} />
				<div>
					<style dangerouslySetInnerHTML={{ __html: eval(css) }} />
					<span>{out}</span>
					{!isAmp && <div dangerouslySetInnerHTML={{ __html: globalBodyScript }} />}
				</div>
			</>;
		}
	}
	else if (pageJson) { //csr

		const ctcsr = getIbeatContentType(pageJson.website.theme.page.type);
		// let isIbeatscript = (typeof window !== 'undefined' && undefined !== window && window) ? IBEAT_SCRIPT.WINDOW_OBJECT.replace('CONTENT_TYPE', ctcsr) : "";
		let isIbeatscript = IBEAT_SCRIPT.WINDOW_OBJECT.replace('CONTENT_TYPE', ctcsr);
		isIbeatscript = isIbeatscript + IBEAT_SCRIPT.ENV;

		let seoData:any = getSeoData(pageJson.website, seoDatas[0]);
		seoData = seoData + headerScriptsStrDefault + ((isIbeat && ctcsr && !isAmp) && isIbeatscript) + (!isAmp && globalHeaderScript);

		return <>
			<Helmet>{seoData}</Helmet>
			{/* <head dangerouslySetInnerHTML={{ __html: seoData }} /> */}
			<div>
				<style dangerouslySetInnerHTML={{ __html: eval(css) }} />
				<span>{out}</span>
				{!isAmp && <div dangerouslySetInnerHTML={{ __html: globalBodyScript }} />}
			</div>
		</>;
	}
};

function convertToDivElements(components: any) {
	return components.map((element: any, index: any) => (
		ReactDOMServer.renderToString(<div key={index}>{element}</div>)
	));
}


function getUniqueScriptTags(htmlString: any) {
	const scriptTagRegex = /<script\b[^>]*>([\s\S]*?)<\/script>/gi;
	const scriptTags = new Set();
	let match: any;

	while ((match = scriptTagRegex.exec(htmlString)) !== null) {
		scriptTags.add(match[0]);
	}
	return Array.from(scriptTags).join('');
}

const fetSetDataFromComps = (comps, original_arr, i_index, seoDatas) => {
	outer:
	if (comps && Array.isArray(comps)) {
		comps.forEach((i_comps, ii) => {
			fetSetDataFromComps(i_comps, original_arr, ii, seoDatas);
		});
	} else if (comps && typeof comps.components !== 'undefined' && comps.components && Array.isArray(comps.components)) {
		comps.components.forEach((i_comps: any, ii: any) => {
			fetSetDataFromComps(i_comps, original_arr, ii, seoDatas);
		});
	} else {
		if (!SEO_DATA_GLOBAL && comps && typeof comps.attributes !== 'undefined' && typeof comps.attributes.EXPORTED_DATA !== 'undefined' && typeof comps.attributes.EXPORTED_DATA.SEO_DATA !== 'undefined') {
			if (!comps.attributes.EXPORTED_DATA.SEO_DATA) {
				ReactDOMServer.renderToString(<div key={'iii__' + i_index}>{original_arr}</div>)
			}

			if (comps.attributes.EXPORTED_DATA.SEO_DATA) {
				SEO_DATA_GLOBAL = comps.attributes.EXPORTED_DATA.SEO_DATA;
				if (SEO_DATA_GLOBAL) {
					seoDatas.push(SEO_DATA_GLOBAL);
					SEO_DATA_GLOBAL = null;
				}
				break outer;
			}
		}
	}
}

const objectToStylesheet = (cssObject: any) => {
	let stylesheet = '';

	for (const selector in cssObject) {
		if (cssObject.hasOwnProperty(selector)) {
			const rules = cssObject[selector];

			let rulesString = '';
			for (const property in rules) {
				if (rules.hasOwnProperty(property)) {
					const value = rules[property];
					rulesString += `  ${property}: ${value}; `;
				}
			}

			stylesheet += `${selector} { ${rulesString}} `;
		}
	}

	return stylesheet;
}

export const replaceIdsInTheURL = (obj: any, primaryComponents: any, compiledFlag: boolean, isSSR: Object, headerScripts: any, url: any) => {
	let msid: any = getMSID();
	if (url) {
		msid = getMSID(url);
	}
	const processObject = (object: any) => {
		if (object == null) {
			return;
		}
		if (object.type) {
			if (compiledFlag == false) {
				if (PRIMARY_COMPONENTS.includes(object.type)) {
					primaryComponents.push(object);
				}
				//For Ad script component header script
				if (object.type && object.type !== undefined && (object.type === 'Ad Scripts' || object.type === 'Ad Scripts Amp' || object.type === 'Ad Scripts Two')
					&& object.attributes.headAdScript !== undefined && object.attributes.headAdScript !== '') {
					const headScr = object.attributes.headAdScript.trim();
					headerScripts.push(headScr);
					object.attributes.headAdScript = '';
					object.headAdScript = '';
				}
				//For component level header script
				if (object.attributes && object.attributes.headAdScript && object.attributes.headAdScript !== undefined && object.attributes.headAdScript !== '') {
					const headScr = object.attributes.headAdScript.trim();
					headerScripts.push(headScr);
				}
				if ((PRIMARY_COMPONENTS.includes(object.type) || SECONDARY_COMPONENTS.includes(object.type))
					&& !isSSR) {
					if (typeof object.attributes.mappings === 'object' && object.attributes.mappings.type === 'pathquery' && object.attributes.mappings.requestKey === 'msid') {
						eval(object.attributes.mappings.value);
						object.attributes.ENDPOINTS.forEach((o: any) => { if (o.isSSR) { o.isSSR = false } });
					}
				}
			}
			else if (compiledFlag) {
				primaryComponents.forEach((comp: any) => {
					if (typeof comp.props.data === "undefined") {
						comp = comp.props.children
					}

					if (typeof comp.props.data === "undefined") {
						comp = comp.props.children
					}

					//sibling requires data
					if (typeof object.attributes !== 'undefined' &&
						typeof object.attributes.mappings !== 'undefined' &&
						typeof object.attributes.parentComponent !== 'undefined' &&
						object.attributes.mappings.type === 'siblings' &&
						comp.props.data.type === object.attributes.parentComponent &&
						comp.props.exportedKeys != null) {
						//pid
						if (typeof comp.props.exportedKeys.pid !== 'undefined'
							&& comp.props.exportedKeys.pid !== null
							&& object.attributes.mappings.requestKey === 'pid') {
							const pid = comp.props.exportedKeys.pid;
							eval(object.attributes.mappings.value);
							object.attributes.ENDPOINTS.forEach((o: any) => { if (o.isSSR) { o.isSSR = false } });
						}
						//authorid
						if (typeof comp.props.exportedKeys.authorid !== 'undefined'
							&& comp.props.exportedKeys.authorid !== null
							&& object.attributes.mappings.requestKey === 'authorid') {
							const authorid = comp.props.exportedKeys.authorid;
							eval(object.attributes.mappings.value);
							object.attributes.ENDPOINTS.forEach((o: any) => { if (o.isSSR) { o.isSSR = false } });
						}
						//tags
						if (typeof comp.props.exportedKeys.tags !== 'undefined'
							&& comp.props.exportedKeys.tags !== null
							&& object.attributes.mappings.requestKey === 'tags') {
							const tags = comp.props.exportedKeys.tags;
							eval(object.attributes.mappings.value);
							object.attributes.ENDPOINTS.forEach((o: any) => { if (o.isSSR) { o.isSSR = false } });
						}
						//sectionid
						if (typeof comp.props.exportedKeys.sectionid !== 'undefined'
							&& comp.props.exportedKeys.sectionid !== null
							&& object.attributes.mappings.requestKey === 'sectionid') {
							const sectionid = comp.props.exportedKeys.sectionid;
							eval(object.attributes.mappings.value);
							object.attributes.ENDPOINTS.forEach((o: any) => { if (o.isSSR) { o.isSSR = false } });
						}
					}
				});


			}
		}
		// Iterate through object properties
		for (let key in object) {
			if (object.hasOwnProperty(key)) {
				if (typeof object[key] === 'object') {
					processObject(object[key]);
				}
			}
		}
	};
	// Start processing the object
	processObject(obj);
	return primaryComponents;
};

export function fetchMedleyData(tenantId: any, key: any) {

	const url = 'https://cdsfeed-ext.indiatimes.com/cds-feed-ext/jcms/cloud/medley';

	const data = {
		tenantId: tenantId,
		key: key,
		searchTitleFlag: true,
		searchAliasesFlag: true,
		exactMatchQueryFlag: true
	};

	return fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	})
		.then(response => {
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			return response.json();
		})
		.then(result => {
			return result; // Return the result to the caller
		})
		.catch(error => {
			console.error('Error fetching data:', error);
			throw error; // Re-throw error for handling by the caller if needed
		});
};

export async function fetchMedleyDataAwait(tenantId: any, key: any) {

	const url = 'https://cdsfeed-ext.indiatimes.com/cds-feed-ext/jcms/cloud/medley';

	const data = {
		tenantId: tenantId,
		key: key,
		searchTitleFlag: true,
		searchAliasesFlag: true,
		exactMatchQueryFlag: true
	};

	return fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	})
		.then(response => {
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			return response.json();
		})
		.then(result => {
			return result; // Return the result to the caller
		})
		.catch(error => {
			console.error('Error fetching data:', error);
			throw error; // Re-throw error for handling by the caller if needed
		});
};