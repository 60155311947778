import React from 'react';
import StaticPage from '@components/StaticPage/StaticPage';
import ArticleShow from '@components/ArticleShow/ArticleShow';
import AmpArticleShow from '@components/ArticleShow/AmpArticleShow';
import AmpVideoShow from '@components/VideoShow/AmpVideoShow';
import PhotoList from '@components/PhotoList/PhotoList';
import PhotoShow from '@components/PhotoShow/PhotoShow';
import VideoList from '@components/VideoList/VideoList';
import VideoShow from '@components/VideoShow/VideoShow';
import Podcast from '@components/Podcast/Podcast';
import TopicPage from '@components/TopicPage/TopicPage';
import TagPage from '@components/TagPage/TagPage';
import Home from '@components/Home/Home';
import ArticleList from '@components/ArticleList/ArticleList';
import AmpPhotoShow from '@components/PhotoShow/AmpPhotoShow';
import PodcastList from '@components/PodcastList/PodcastList';
import AuthorDetail from '@components/AuthorDetail/AuthorDetail';
import ErrorPage from '@components/ErrorPage/ErrorPage';
import WebstoryList from '@components/WebstoryList/WebstoryList';
import FAQList from '@components/FAQList/FAQList';
import { PAGE_TYPES } from '@fronend/common/constants';
import QuizList from '@components/QuizList/QuizList';
import QuizShow from '@components/QuizShow/QuizShow';
import SurveyList from '@components/SurveyList/SurveyList';
import SurveyShow from '@components/SurveyShow/SurveyShow';
import ArticleShowPreview from '@components/ArticleShowPreview/ArticleShowPreview';
import AmpArticleShowPreview from '@components/ArticleShowPreview/AmpArticleShowPreview';
import PhotoShowPreview from '@components/PhotoShowPreview/PhotoShowPreview';
import AmpPhotoShowPreview from '@components/PhotoShowPreview/AmpPhotoShowPreview';

const routes = [
	
	{ path: '/', element: <Home />, ttl: 60, pageType: PAGE_TYPES.HomePage },

	{ path: '/articlelist/:msid.html', element: <ArticleList />, ttl: 60, pageType: PAGE_TYPES.ArticleList, loading: "EAGER" },
	{ path: '/:section1/articlelist/:msid.html', element: <ArticleList />, ttl: 60, pageType: PAGE_TYPES.ArticleList, loading: "EAGER" },
	{ path: '/:section1/:section2/articlelist/:msid.html', element: <ArticleList />, ttl: 60, pageType: PAGE_TYPES.ArticleList, loading: "EAGER" },
	{ path: '/:section1/:section2/:section3/articlelist/:msid.html', element: <ArticleList />, ttl: 60, pageType: PAGE_TYPES.ArticleList, loading: "EAGER" },
	{ path: '/:section1/:section2/:section3/:section4/articlelist/:msid.html', element: <ArticleList />, ttl: 60, pageType: PAGE_TYPES.ArticleList, loading: "EAGER" },

	{ path: '/:storyurl/articleshow/:msid.html', element: <ArticleShow />, ttl: 3600, pageType: PAGE_TYPES.ArticleShow },
	{ path: '/:section1/:storyurl/articleshow/:msid.html', element: <ArticleShow />, ttl: 3600, pageType: PAGE_TYPES.ArticleShow },
	{ path: '/:section1/:section2/:storyurl/articleshow/:msid.html', element: <ArticleShow />, ttl: 3600, pageType: PAGE_TYPES.ArticleShow },
	{ path: '/:section1/:section2/:section3/:storyurl/articleshow/:msid.html', element: <ArticleShow />, ttl: 3600, pageType: PAGE_TYPES.ArticleShow },
	{ path: '/:section1/:section2/:section3/:section4/:storyurl/articleshow/:msid.html', element: <ArticleShow />, ttl: 3600, pageType: PAGE_TYPES.ArticleShow },
	
	{ path: '/:storyurl/amp_articleshow/:msid.html', element: <AmpArticleShow />, ttl: 3600, pageType: PAGE_TYPES.AmpArticleShow, isAmp: true },
	{ path: '/:section1/:storyurl/amp_articleshow/:msid.html', element: <AmpArticleShow />, ttl: 3600, pageType: PAGE_TYPES.AmpArticleShow, isAmp: true },
	{ path: '/:section1/:section2/:storyurl/amp_articleshow/:msid.html', element: <AmpArticleShow />, ttl: 3600, pageType: PAGE_TYPES.AmpArticleShow, isAmp: true },
	{ path: '/:section1/:section2/:section3/:storyurl/amp_articleshow/:msid.html', element: <AmpArticleShow />, ttl: 3600, pageType: PAGE_TYPES.AmpArticleShow, isAmp: true },
	{ path: '/:section1/:section2/:section3/:section4/:storyurl/amp_articleshow/:msid.html', element: <AmpArticleShow />, ttl: 3600, pageType: PAGE_TYPES.AmpArticleShow, isAmp: true },


	{ path: '/:storyurl/preview_articleshow/:msid.html', element: <ArticleShowPreview />, ttl: -1, pageType: PAGE_TYPES.ArticleShowPreview },
	{ path: '/:section1/:storyurl/preview_articleshow/:msid.html', element: <ArticleShowPreview />, ttl: -1, pageType: PAGE_TYPES.ArticleShowPreview },
	{ path: '/:section1/:section2/:storyurl/preview_articleshow/:msid.html', element: <ArticleShowPreview />, ttl: -1, pageType: PAGE_TYPES.ArticleShowPreview },
	{ path: '/:section1/:section2/:section3/:storyurl/preview_articleshow/:msid.html', element: <ArticleShowPreview />, ttl: -1, pageType: PAGE_TYPES.ArticleShowPreview },
	{ path: '/:section1/:section2/:section3/:section4/:storyurl/preview_articleshow/:msid.html', element: <ArticleShowPreview />, ttl: -1, pageType: PAGE_TYPES.ArticleShowPreview },
	
	{ path: '/:storyurl/preview_amp_articleshow/:msid.html', element: <AmpArticleShowPreview />, ttl: -1, pageType: PAGE_TYPES.AmpArticleShowPreview, isAmp: true },
	{ path: '/:section1/:storyurl/preview_amp_articleshow/:msid.html', element: <AmpArticleShowPreview />, ttl: -1, pageType: PAGE_TYPES.AmpArticleShowPreview, isAmp: true },
	{ path: '/:section1/:section2/:storyurl/preview_amp_articleshow/:msid.html', element: <AmpArticleShowPreview />, ttl: -1, pageType: PAGE_TYPES.AmpArticleShowPreview, isAmp: true },
	{ path: '/:section1/:section2/:section3/:storyurl/preview_amp_articleshow/:msid.html', element: <AmpArticleShowPreview />, ttl: -1, pageType: PAGE_TYPES.AmpArticleShowPreview, isAmp: true },
	{ path: '/:section1/:section2/:section3/:section4/:storyurl/preview_amp_articleshow/:msid.html', element: <AmpArticleShowPreview />, ttl: -1, pageType: PAGE_TYPES.AmpArticleShowPreview, isAmp: true },


	{ path: '/:storyurl/amp_videoshow/:msid.html', element: <AmpVideoShow />, ttl: 3600, pageType: PAGE_TYPES.AmpVideoShow, isAmp: true },
	{ path: '/:section1/:storyurl/amp_videoshow/:msid.html', element: <AmpVideoShow />, ttl: 3600, pageType: PAGE_TYPES.AmpVideoShow, isAmp: true },
	{ path: '/:section1/:section2/:storyurl/amp_videoshow/:msid.html', element: <AmpVideoShow />, ttl: 3600, pageType: PAGE_TYPES.AmpVideoShow, isAmp: true },
	{ path: '/:section1/:section2/:section3/:storyurl/amp_videoshow/:msid.html', element: <AmpVideoShow />, ttl: 3600, pageType: PAGE_TYPES.AmpVideoShow, isAmp: true },
	{ path: '/:section1/:section2/:section3/:section4/:storyurl/amp_videoshow/:msid.html', element: <AmpVideoShow />, ttl: 3600, pageType: PAGE_TYPES.AmpVideoShow, isAmp: true },

	{ path: '/webstorylist/:msid.html', element: <WebstoryList />, ttl: 60, pageType: PAGE_TYPES.WebstoryList, loading: "EAGER" },
	{ path: '/:section1/webstorylist/:msid.html', element: <WebstoryList />, ttl: 60, pageType: PAGE_TYPES.WebstoryList, loading: "EAGER" },
	{ path: '/:section1/:section2/webstorylist/:msid.html', element: <WebstoryList />, ttl: 60, pageType: PAGE_TYPES.WebstoryList, loading: "EAGER" },
	{ path: '/:section1/:section2/:section3/webstorylist/:msid.html', element: <WebstoryList />, ttl: 60, pageType: PAGE_TYPES.WebstoryList, loading: "EAGER" },
	{ path: '/:section1/:section2/:section3/:section4/webstorylist/:msid.html', element: <WebstoryList />, ttl: 60, pageType: PAGE_TYPES.WebstoryList, loading: "EAGER" },

	{ path: '/photolist/:msid.html', element: <PhotoList />, ttl: 60, pageType: PAGE_TYPES.PhotoList, loading: "EAGER" },
	{ path: '/:section1/photolist/:msid.html', element: <PhotoList />, ttl: 60, pageType: PAGE_TYPES.PhotoList, loading: "EAGER" },
	{ path: '/:section1/:section2/photolist/:msid.html', element: <PhotoList />, ttl: 60, pageType: PAGE_TYPES.PhotoList, loading: "EAGER" },
	{ path: '/:section1/:section2/:section3/photolist/:msid.html', element: <PhotoList />, ttl: 60, pageType: PAGE_TYPES.PhotoList, loading: "EAGER" },
	{ path: '/:section1/:section2/:section3/:section4/photolist/:msid.html', element: <PhotoList />, ttl: 60, pageType: PAGE_TYPES.PhotoList, loading: "EAGER" },

	{ path: '/:storyurl/photoshow/:msid.html', element: <PhotoShow />, ttl: 3600, pageType: PAGE_TYPES.PhotoShow, loading: "EAGER" },
	{ path: '/:section1/:storyurl/photoshow/:msid.html', element: <PhotoShow />, ttl: 3600, pageType: PAGE_TYPES.PhotoShow, loading: "EAGER" },
	{ path: '/:section1/:section2/:storyurl/photoshow/:msid.html', element: <PhotoShow />, ttl: 3600, pageType: PAGE_TYPES.PhotoShow, loading: "EAGER" },
	{ path: '/:section1/:section2/:section3/:storyurl/photoshow/:msid.html', element: <PhotoShow />, ttl: 3600, pageType: PAGE_TYPES.PhotoShow, loading: "EAGER" },
	{ path: '/:section1/:section2/:section3/:section4/:storyurl/photoshow/:msid.html', element: <PhotoShow />, ttl: 3600, pageType: PAGE_TYPES.PhotoShow, loading: "EAGER" },

	{ path: '/:storyurl/amp_photoshow/:msid.html', element: <AmpPhotoShow />, ttl: 3600, pageType: PAGE_TYPES.AmpPhotoShow, loading: "EAGER", isAmp: true },
	{ path: '/:section1/:storyurl/amp_photoshow/:msid.html', element: <AmpPhotoShow />, ttl: 3600, pageType: PAGE_TYPES.AmpPhotoShow, loading: "EAGER", isAmp: true },
	{ path: '/:section1/:section2/:storyurl/amp_photoshow/:msid.html', element: <AmpPhotoShow />, ttl: 3600, pageType: PAGE_TYPES.AmpPhotoShow, loading: "EAGER", isAmp: true },
	{ path: '/:section1/:section2/:section3/:storyurl/amp_photoshow/:msid.html', element: <AmpPhotoShow />, ttl: 3600, pageType: PAGE_TYPES.AmpPhotoShow, loading: "EAGER", isAmp: true },
	{ path: '/:section1/:section2/:section3/:section4/:storyurl/amp_photoshow/:msid.html', element: <AmpPhotoShow />, ttl: 3600, pageType: PAGE_TYPES.AmpPhotoShow, loading: "EAGER", isAmp: true },
	

	{ path: '/:storyurl/preview_photoshow/:msid.html', element: <PhotoShowPreview />, ttl: -1, pageType: PAGE_TYPES.PhotoShowPreview, loading: "EAGER" },
	{ path: '/:section1/:storyurl/preview_photoshow/:msid.html', element: <PhotoShowPreview />, ttl: -1, pageType: PAGE_TYPES.PhotoShowPreview, loading: "EAGER" },
	{ path: '/:section1/:section2/:storyurl/preview_photoshow/:msid.html', element: <PhotoShowPreview />, ttl: -1, pageType: PAGE_TYPES.PhotoShowPreview, loading: "EAGER" },
	{ path: '/:section1/:section2/:section3/:storyurl/preview_photoshow/:msid.html', element: <PhotoShowPreview />, ttl: -1, pageType: PAGE_TYPES.PhotoShowPreview, loading: "EAGER" },
	{ path: '/:section1/:section2/:section3/:section4/:storyurl/preview_photoshow/:msid.html', element: <PhotoShowPreview />, ttl: -1, pageType: PAGE_TYPES.PhotoShowPreview, loading: "EAGER" },

	{ path: '/:storyurl/preview_amp_photoshow/:msid.html', element: <AmpPhotoShowPreview />, ttl: -1, pageType: PAGE_TYPES.AmpPhotoShowPreview, loading: "EAGER", isAmp: true },
	{ path: '/:section1/:storyurl/preview_amp_photoshow/:msid.html', element: <AmpPhotoShowPreview />, ttl: -1, pageType: PAGE_TYPES.AmpPhotoShowPreview, loading: "EAGER", isAmp: true },
	{ path: '/:section1/:section2/:storyurl/preview_amp_photoshow/:msid.html', element: <AmpPhotoShowPreview />, ttl: -1, pageType: PAGE_TYPES.AmpPhotoShowPreview, loading: "EAGER", isAmp: true },
	{ path: '/:section1/:section2/:section3/:storyurl/preview_amp_photoshow/:msid.html', element: <AmpPhotoShowPreview />, ttl: -1, pageType: PAGE_TYPES.AmpPhotoShowPreview, loading: "EAGER", isAmp: true },
	{ path: '/:section1/:section2/:section3/:section4/:storyurl/preview_amp_photoshow/:msid.html', element: <AmpPhotoShowPreview />, ttl: -1, pageType: PAGE_TYPES.AmpPhotoShowPreview, loading: "EAGER", isAmp: true },
	

	{ path: '/videolist/:msid.html', element: <VideoList />, ttl: 60, pageType: PAGE_TYPES.VideoList, loading: "EAGER" },
	{ path: '/:section1/videolist/:msid.html', element: <VideoList />, ttl: 60, pageType: PAGE_TYPES.VideoList, loading: "EAGER" },
	{ path: '/:section1/:section2/videolist/:msid.html', element: <VideoList />, ttl: 60, pageType: PAGE_TYPES.VideoList, loading: "EAGER" },
	{ path: '/:section1/:section2/:section3/videolist/:msid.html', element: <VideoList />, ttl: 60, pageType: PAGE_TYPES.VideoList, loading: "EAGER" },
	{ path: '/:section1/:section2/:section3/:section4/videolist/:msid.html', element: <VideoList />, ttl: 60, pageType: PAGE_TYPES.VideoList, loading: "EAGER" },

	{ path: '/:storyurl/videoshow/:msid.html', element: <VideoShow />, ttl: 3600, pageType: PAGE_TYPES.VideoShow },
	{ path: '/:section1/:storyurl/videoshow/:msid.html', element: <VideoShow />, ttl: 3600, pageType: PAGE_TYPES.VideoShow },
	{ path: '/:section1/:section2/:storyurl/videoshow/:msid.html', element: <VideoShow />, ttl: 3600, pageType: PAGE_TYPES.VideoShow },
	{ path: '/:section1/:section2/:section3/:storyurl/videoshow/:msid.html', element: <VideoShow />, ttl: 3600, pageType: PAGE_TYPES.VideoShow },
	{ path: '/:section1/:section2/:section3/:section4/:storyurl/videoshow/:msid.html', element: <VideoShow />, ttl: 3600, pageType: PAGE_TYPES.VideoShow },

	{ path: '/:storyurl/podcast/:msid.html', element: <Podcast />, ttl: 3600, pageType: PAGE_TYPES.Podcast },
	{ path: '/:section1/:storyurl/podcast/:msid.html', element: <Podcast />, ttl: 3600, pageType: PAGE_TYPES.Podcast },
	{ path: '/:section1/:section2/:storyurl/podcast/:msid.html', element: <Podcast />, ttl: 3600, pageType: PAGE_TYPES.Podcast },
	{ path: '/:section1/:section2/:section3/:storyurl/podcast/:msid.html', element: <Podcast />, ttl: 3600, pageType: PAGE_TYPES.Podcast },
	{ path: '/:section1/:section2/:section3/:section4/:storyurl/podcast/:msid.html', element: <Podcast />, ttl: 3600, pageType: PAGE_TYPES.Podcast },

	{ path: '/podcastlist/:msid.html', element: <PodcastList />, ttl: 60, pageType: PAGE_TYPES.PodcastList, loading: "EAGER" },
	{ path: '/:section1/podcastlist/:msid.html', element: <PodcastList />, ttl: 60, pageType: PAGE_TYPES.PodcastList, loading: "EAGER" },
	{ path: '/:section1/:section2/podcastlist/:msid.html', element: <PodcastList />, ttl: 60, pageType: PAGE_TYPES.PodcastList, loading: "EAGER" },
	{ path: '/:section1/:section2/:section3/podcastlist/:msid.html', element: <PodcastList />, ttl: 60, pageType: PAGE_TYPES.PodcastList, loading: "EAGER" },
	{ path: '/:section1/:section2/:section3/:section4/podcastlist/:msid.html', element: <PodcastList />, ttl: 60, pageType: PAGE_TYPES.PodcastList, loading: "EAGER" },

	{ path: '/faqlist/:msid.html', element: <FAQList />, ttl: 60, pageType: PAGE_TYPES.FAQList, loading: "EAGER" },
	{ path: '/:section1/faqlist/:msid.html', element: <FAQList />, ttl: 60, pageType: PAGE_TYPES.FAQList, loading: "EAGER" },
	{ path: '/:section1/:section2/faqlist/:msid.html', element: <FAQList />, ttl: 60, pageType: PAGE_TYPES.FAQList, loading: "EAGER" },
	{ path: '/:section1/:section2/:section3/faqlist/:msid.html', element: <FAQList />, ttl: 60, pageType: PAGE_TYPES.FAQList, loading: "EAGER" },
	{ path: '/:section1/:section2/:section3/:section4/faqlist/:msid.html', element: <FAQList />, ttl: 60, pageType: PAGE_TYPES.FAQList, loading: "EAGER" },

	{ path: '/quizlist/:msid.html', element: <QuizList />, ttl: 60, pageType: PAGE_TYPES.QuizList, loading: "EAGER" },
	{ path: '/:section1/quizlist/:msid.html', element: <QuizList />, ttl: 60, pageType: PAGE_TYPES.QuizList, loading: "EAGER" },
	{ path: '/:section1/:section2/quizlist/:msid.html', element: <QuizList />, ttl: 60, pageType: PAGE_TYPES.QuizList, loading: "EAGER" },
	{ path: '/:section1/:section2/:section3/quizlist/:msid.html', element: <QuizList />, ttl: 60, pageType: PAGE_TYPES.QuizList, loading: "EAGER" },
	{ path: '/:section1/:section2/:section3/:section4/quizlist/:msid.html', element: <QuizList />, ttl: 60, pageType: PAGE_TYPES.QuizList, loading: "EAGER" },

	{ path: '/:storyurl/quizshow/:msid.html', element: <QuizShow />, ttl: 3600, pageType: PAGE_TYPES.QuizShow },
	{ path: '/:section1/:storyurl/quizshow/:msid.html', element: <QuizShow />, ttl: 3600, pageType: PAGE_TYPES.QuizShow },
	{ path: '/:section1/:section2/:storyurl/quizshow/:msid.html', element: <QuizShow />, ttl: 3600, pageType: PAGE_TYPES.QuizShow },
	{ path: '/:section1/:section2/:section3/:storyurl/quizshow/:msid.html', element: <QuizShow />, ttl: 3600, pageType: PAGE_TYPES.QuizShow },
	{ path: '/:section1/:section2/:section3/:section4/:storyurl/quizshow/:msid.html', element: <QuizShow />, ttl: 3600, pageType: PAGE_TYPES.QuizShow },

	{ path: '/surveylist/:msid.html', element: <SurveyList />, ttl: 60, pageType: PAGE_TYPES.SurveyList, loading: "EAGER" },
	{ path: '/:section1/surveylist/:msid.html', element: <SurveyList />, ttl: 60, pageType: PAGE_TYPES.SurveyList, loading: "EAGER" },
	{ path: '/:section1/:section2/surveylist/:msid.html', element: <SurveyList />, ttl: 60, pageType: PAGE_TYPES.SurveyList, loading: "EAGER" },
	{ path: '/:section1/:section2/:section3/surveylist/:msid.html', element: <SurveyList />, ttl: 60, pageType: PAGE_TYPES.SurveyList, loading: "EAGER" },
	{ path: '/:section1/:section2/:section3/:section4/surveylist/:msid.html', element: <SurveyList />, ttl: 60, pageType: PAGE_TYPES.SurveyList, loading: "EAGER" },

	{ path: '/:storyurl/surveyshow/:msid.html', element: <SurveyShow />, ttl: 3600, pageType: PAGE_TYPES.SurveyShow },
	{ path: '/:section1/:storyurl/surveyshow/:msid.html', element: <SurveyShow />, ttl: 3600, pageType: PAGE_TYPES.SurveyShow },
	{ path: '/:section1/:section2/:storyurl/surveyshow/:msid.html', element: <SurveyShow />, ttl: 3600, pageType: PAGE_TYPES.SurveyShow },
	{ path: '/:section1/:section2/:section3/:storyurl/surveyshow/:msid.html', element: <SurveyShow />, ttl: 3600, pageType: PAGE_TYPES.SurveyShow },
	{ path: '/:section1/:section2/:section3/:section4/:storyurl/surveyshow/:msid.html', element: <SurveyShow />, ttl: 3600, pageType: PAGE_TYPES.SurveyShow },

	{ path: '/topics/:keyword', element: <TopicPage />, ttl: 60, pageType: PAGE_TYPES.TopicPage },
	{ path: '/tags/:keyword', element: <TagPage />, ttl: 60, pageType: PAGE_TYPES.TagPage },
	{ path: '/:authorname/author/:msid.html', element: <AuthorDetail />, ttl: 60, pageType: PAGE_TYPES.AuthorDetail },
	{ path: '/staticpage/:msid.html', element: <StaticPage />, ttl: 3600, pageType: PAGE_TYPES.StaticPage },

	{ path: '*', element: <ErrorPage />, ttl: 60, pageType: PAGE_TYPES.ErrorPage },

];

export default routes;
