import logger from "../../logging/log";

export const API_BASE = '';

export const STAGING_DOMAIN_SUFFIX = 'getm360.com';

import { MODULE as DEFAULT_ENV } from './../../../env.json';
import { MODULE as DEV_ENV } from './../../../env.development.json';
import { MODULE as QA_ENV } from './../../../env.qa.json';
import { MODULE as PROD_ENV } from './../../../env.production.json';
import { MODULE as PREPROD_ENV } from './../../../env.preprod.json';

export const SUPER_USER_KEY = "ak";
export let SUPER_USER_VALUE = "655b7221643072a975bee164";

export let ENV = DEFAULT_ENV;
let environment = 'production';
if (typeof process !== 'undefined' && undefined !== process && process && process.env && process.env.platform) {
	environment = process.env.platform;

	if (environment == 'development') {
		ENV = DEV_ENV;
	} else if (environment == 'qa') {
		ENV = QA_ENV;
	} else if ( environment == 'preprod' ) {
		ENV = PREPROD_ENV;
	} else if ( environment == 'production' ) {
		ENV = PROD_ENV;
	}
}

export let IBEAT_SCRIPT = {
	STAGING: '<script defer="" src="https://agi-static.indiatimes.com/cms-common/stg/stg-ibeat.min.js"></script>',
	LIVE: '<script defer="" src="https://agi-static.indiatimes.com/cms-common/ibeat.min.js"></script>',
	WINDOW_OBJECT: '<script> window._ibeat_track = {"ct": "CONTENT_TYPE"};</script>',
	ENV: '<script defer="" src="https://agi-static.indiatimes.com/cms-common/ibeat.min.js"></script>'
}

logger.info(environment, "environment value is: ");
if (environment == 'qa') {
	IBEAT_SCRIPT.ENV = IBEAT_SCRIPT.STAGING;
	SUPER_USER_VALUE = "655b7221643072a975bee163";
}

if (environment == 'development') {
	IBEAT_SCRIPT.ENV = IBEAT_SCRIPT.STAGING;
	SUPER_USER_VALUE = "655b7221643072a975bee163";
}

export let API_URLS = {
	NEWSROOM_API: ENV.REACT_APP_NEWSROOM_API
}

//console.log("API_URLSAPI_URLS", API_URLS, ENV);
const child = logger.child(API_URLS);
child.info('API_URLSAPI_URLS');

export const PAGE_TYPES = {
	HomePage: 'HomePage',
	ArticleList: 'ArticleList',
	ArticleShow: 'ArticleShow',
	ArticleShowPreview: 'ArticleShowPreview',
	PhotoList: 'PhotoList',
	PhotoShow: 'PhotoShow',
	PhotoShowPreview: 'PhotoShowPreview',
	Podcast: 'Podcast',
	StaticPage: 'StaticPage',
	TopicPage: 'TopicPage',
	TagPage: 'TagPage',
	VideoList: 'VideoList',
	VideoShow: 'VideoShow',
	ErrorPage: 'ErrorPage',
	AuthorDetail: 'AuthorDetail',
	AmpArticleShow: 'AmpArticleShow',
	AmpArticleShowPreview: 'AmpArticleShowPreview',
	AmpVideoShow: 'AmpVideoShow',
	WebstoryList: 'WebstoryList',
	AmpPhotoShow: 'AmpPhotoShow',
	AmpPhotoShowPreview: 'AmpPhotoShowPreview',
	PodcastList: 'PodcastList',
	FAQList: 'FAQ List',
	QuizShow: 'QuizShow',
	SurveyShow: 'SurveyShow',
	QuizList: 'QuizList',
	SurveyList: 'SurveyList'
};

export const EVENT_TYPES = {
	FETCH_PAGE_DATA: 'FETCH_PAGE_DATA',
	SET_PAGE_DATA: 'SET_PAGE_DATA',
	FETCH_PAGE_DATA_AJAX_SUCCESS: 'FETCH_PAGE_DATA_AJAX_SUCCESS',
};

export const SERVER_ENVINORMENTS = {
	STAGING: 1,
	PRODUCTION: 2
}

export const apiUrl_getClientWithDomain = `${API_URLS.NEWSROOM_API}/newsroomApi/rendering/getClientWithDomain?domainName={DomainName}`;
export const apiUrl_getThemeByClientAndWebsite = `${API_URLS.NEWSROOM_API}/newsroomApi/rendering/getPageTemplateByType?clientId={ClientId}&websiteId={WebsiteId}&pageType={pageType}&env={Environment}`;
export const apiUrl_getDestinationPath = `${API_URLS.NEWSROOM_API}/newsroomApi/rendering/getDestinationPath?clientId={ClientId}&websiteId={WebsiteId}&sourcePath={sourcePath}`;
export const apiUrl_getSitemapData = `${API_URLS.NEWSROOM_API}/newsroomApi/news-sitemap/{domain}/{hostId}/{day}`;
export const apiUrl_getSitemapDataDivision = `${API_URLS.NEWSROOM_API}/newsroomApi/news-sitemap/{domain}/{hostId}/{day}?curpg={curpg}`;
export const apiUrl_sectionHeader = `https://{DOMAIN}/cds-feed-ext/moderate/ux/hf/m360/get?&hostId={hostId}&key=section-{msid}&fv=1`;

export interface UrlRedirectionData {
	_id: string;
	clientId: number;
	websiteId: number;
	sourcePath: string;
	destinationPath: string;
	redirectionType: string;
	isSoftRedirection: boolean;
	status: boolean;
	createdAt: string;
	updatedAt: string;
}

export const PRIMARY_COMPONENTS = [
	"List View",
	"Article Show",
	"Video Show",
	"Author Profile Component",
	"Slide Show",
	"Static Page",
	"Author Show",
	"Article Show Amp",
	"List View Two",
	"WebStoriesList Two",
	"MediaList Two",
	"PhotoList Two",
	"Article Show Two",
	"Video Show Two",
	"Slide Show Two",
	"Author Show Two",
	"Static Page Two",
	"PageNotFound Two",
	"Article Show Two Amp",
	"Photo Show Two Amp",
	"Video Show Two Amp",
	"Podcast Two",
	"Footer Two",
	"Header Two",
	"Footer",
	"Header",
	"Bread Crumbs",
	"BreadCrumbs Two",
	"Grid View2",
	"List View Three",
	"WebStoriesList Three",
	"MediaList Three",
	"PhotoList Three",
	"Article Show Three",
	"Video Show Three",
	"Slide Show Three",
	"Author Show Three",
	"Static Page Three",
	"PageNotFound Three",
	"Article Show Three Amp",
	"Photo Show Three Amp",
	"Video Show Three Amp",
	"Podcast Three",
	"Quiz Show",
	"Survey Show",
	"Web Stories2"
];

export const COMMON_CONST_PARAMS = {
	"REQUEST_URL": "req.url",
	"REQUEST_HOSTNAME": "req.hostname",
	"clientHostId": "cId",
	"PAGE_TYPE": "pageType",
};

export const SECONDARY_COMPONENTS = [
	"Grid View",
	"Breaking News",
	"Carousel Card Block",
	"Similar Stories",
	"Hero Layout",
	"Circular Card Carousel",
	"Web Stories",
	"Header Amp",
	"Footer Amp",
	"Breaking News Two",
	"Quick View Two",
	"Home Grid View Two",
	"WebStories Two",
	"HeroLayout Two",
	"HeroLayout Hr Two",
	"RhsHome Two",
	"GridView Other Two",
	"Ad Scripts Two",
	"RhsHome Other Two",
	"Breaking News Three",
	"Quick View Three",
	"Home Grid View Three",
	"WebStories Three",
	"HeroLayout Three",
	"HeroLayout Hr Three",
	"RhsHome Three",
	"GridView Three",
	"GridView Other Three",
	"Footer Three",
	"Header Three",
	"BreadCrumbs Three",
	"Ad Scripts Three",
	"RhsHome Other Three",
	"HeroLayout with Header",
	"Four Links",
	"Home Web Stories"
];
export const gaTagScript = '<script async class="ga_tag" src="https://www.googletagmanager.com/gtag/js?id={{GA_TAG}}"></script><script class="ga_tag_init">window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag("js", new Date());gtag("config", "{{GA_TAG}}");</script>';
export const gaMustache = '{{GA_TAG}}';

export const Health = 'ok';
export const localhost = '127.0.0.1';

